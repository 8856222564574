import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#F7F9F9',
        transition: '0.3s',
        width: 345,
        margin: 10,
        '&:hover': {
            backgroundColor: '#F9D100',
            cursor: 'pointer'
        }
    }
}))