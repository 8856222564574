import firebase from 'firebase/compat/app'
import 'firebase/firestore'
import {
    getCollectionFiltered,
    saveDocument,
    updateDocument,
    deleteDocument
} from './abm'
import { snapshotToArray } from '../../utils'

const collection = 'addresses'

export default class ClientAddress {
    constructor() {
        this.address = ''
        this.number = ''
        this.description = ''
        this.phone = ''
        this.position = []
        this.principal = false
    }
}

export let getClientAdressesRealTimeUnsubscribe = () => { }

export const getClientAdressesRealTime = (clientId, setState = () => { }) => {
    getClientAdressesRealTimeUnsubscribe = firebase.app().firestore().collection('clients').doc(clientId).collection(collection)
        .onSnapshot(querySnapshot => {
            setState(snapshotToArray(querySnapshot.docs))
        })
}

export const getClientAdresses = (clientId) => getCollectionFiltered({ collection: `clients/${clientId}/${collection}` })

export const deleteAddress = (clientId, id) => deleteDocument(id, `clients/${clientId}/${collection}`)

export const saveAddress = (clientId, data) => saveDocument(refactor(clientId, data), `clients/${clientId}/${collection}`)

export const updateAddress = (clientId, data) => updateDocument(refactor(clientId, data), `clients/${clientId}/${collection}`)


const refactor = (clientId, obj) => ({
    ...obj,
    descriptionToLower: obj.description?.toLowerCase() || '',
    address: obj.address?.toUpperCase() || '',
    clientId,
    updated: new Date()
})