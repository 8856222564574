// metodos de edicion de productos de pedido
import { keyBy } from './index'

//import { TrendingUpRounded } from "@material-ui/icons"


export const generateSmartComposition = prod => {
    const { qty = 0 } = prod
    let smartComposition = []

    if (prod.smartComposition) {
        smartComposition = [...prod.smartComposition]
        // a continuacion se agrega control para cuando se edita la cantidad de productos
        if (prod.smartComposition.length < qty) {
            //const news = qty - prod.smartComposition.length
            //console.log('se agregaron ' + news + ' productos')
            let i = prod.smartComposition.length + 1
            for (i; i <= qty; i++) {
                //console.log('se agrega cod ' + i)
                const _item = {
                    ...prod,
                    qty: 1,
                    _cod: i
                }
                smartComposition.push(_item)
            }
        } else if (prod.smartComposition.length > qty) {
            //const deleted = prod.smartComposition.length - qty
            //console.log('se quitaron ' + deleted + ' productos')
            smartComposition = []
            prod.smartComposition.map((p, i) => {
                const count = i + 1
                if (count <= qty) {
                    smartComposition.push({
                        ...p,
                        _cod: count
                    })
                }
                return p
            })
        }
    } else {
        for (let i = 1; i <= qty; i++) {
            const _item = {
                ...prod,
                qty: 1,
                _cod: i
            }
            smartComposition.push(_item)
        }
    }
    return {
        ...prod,
        smartComposition
    }
}

export const validateOrder = (order, paymentMethods = []) => {
    let validation = {
        valid: true,
        severity: 'success',
        message: 'Success'
    }

    const { productsList = [], addressId, pickup = false, paymentMethod } = order

    if (paymentMethods?.length > 0 && !paymentMethod) {
        validation.valid = false
        validation.severity = 'warning'
        validation.message = `Seleccione método de pago`
        return validation
    }

    if (!addressId && !pickup) {
        validation.valid = false
        validation.severity = 'warning'
        validation.message = `No se ha seleccionado dirección de entrega`
        return validation
    }

    if (productsList?.length === 0) {
        validation.valid = false
        validation.severity = 'warning'
        validation.message = `No se han añadido productos al carrito`
        return validation
    }

    if (productsList?.filter(p => p.qty < 1).length > 0) {
        validation.valid = false
        validation.severity = 'warning'
        validation.message = `No se ha definido una cantidad para algunos productos`
        return validation
    }

    let stop = false
    productsList?.map(product => {
        if (stop)
            return null

        if (product.smartComposition) {
            const { smartComposition = [] } = product

            for (let l = 0; l < smartComposition.length && !stop; l++) {
                const prod = smartComposition[l]
                validation = validateProduct(prod, validation)
                stop = validation.break

                const { variants = [] } = prod

                if (validation.valid) {
                    // eslint-disable-next-line no-loop-func
                    prod?.composition?.some(item => {
                        let _validation = {}

                        if (item.optional && !item.checked)
                            return false

                        validateProduct(item, _validation)
                        if (_validation.valid === false) {
                            const variant = variants.find(v => v.variantGroup === item.variantGroup)
                            // busco si para el mismo variantGroup ya hay otro elemento seleccionado
                            if (variant && variant?.variantId !== item.id)
                                return false
                            validation = _validation
                        }
                        return _validation.valid
                    })
                }
            }

        } else {
            validation = validateProduct(product, validation)
            stop = validation.break
        }

        return null
    })
    return validation
}

// export const createSmartNotesFromOrderProducts = (orderProducts = []) => {
//     let smartNotes = ''
//     //console.log('createSmartNotes', list)
//     orderProducts.forEach((product) => {
//         const { composition = [], variants = [] } = product
//         let firstNote = ''
//         if (orderProducts.length > 1)
//             firstNote = 'Una ' // `${numberToCardinals[_cod]} `

//         let _notes = firstNote
//         composition.forEach(supply => {
//             let text = supply.shortDescription || supply.description || supply.name || ''
//             text = text.toUpperCase()
//             if ((supply.optional || supply.variant) && !supply.checked) {
//                 _notes += `Sin ${text}, `
//             }
//             else if (supply.variantDescription)
//                 _notes += `${supply.variantDescription}, `

//         })
//         if (product.variantDescription)
//             _notes += `${product.variantDescription}, `

//         variants.forEach(variant => {
//             let result = `${variant.variantDescription}, `
//             const subVariants = composition.find(c => c.id === variant.variantId)?.variants?.map(v => v.variantDescription).join()
//             if (subVariants) {
//                 result = `${variant.variantDescription} (${subVariants}), `
//             }
//             _notes += result
//         })

//         if (_notes !== firstNote) {
//             smartNotes += _notes.slice(0, -2) + ' | '
//         }
//     })
//     smartNotes = smartNotes.slice(0, -3).toUpperCase()
//     return smartNotes
// }

export const createSmartNotesFromOrderProducts = (orderProducts = []) => {
    let smartNotes = '';
    let notesList = [];

    orderProducts.forEach((product) => {
        const { composition = [], variants = [], notes = '' } = product;
        let firstNote = '';

        if (orderProducts.length > 1) {
            firstNote = ' ';
        }

        let _notes = firstNote;

        composition.forEach((supply) => {
            let text = supply.shortDescription || supply.description || supply.name || '';
            text = text.toUpperCase();

            if ((supply.optional || supply.variant) && !supply.checked) {
                _notes += `SIN ${text}, `;
            } else if (supply.variantDescription) {
                _notes += `${text}, `;
            } else if (supply.extraChecked) {
                _notes += `EXTRA ${text}, `;
            }
        });

        variants.forEach((variant) => {
            let result = `${variant.variantDescription}, `;
            const subVariants = composition.find((c) => c.id === variant.variantId)?.variants?.map((v) => v.variantDescription).join(', ');

            if (subVariants) {
                result = `${variant.variantDescription} (${subVariants}), `;
            }

            _notes += result;
        });

        if (_notes !== firstNote) {
            _notes = _notes.slice(0, -2) + (notes ? ` (${notes})` : '')
            notesList.push(_notes);
        } else {
            if (notes)
                notesList.push(notes);
        }
    });

    notesList.forEach((note) => {
        smartNotes += `- ${note} |`;
    });

    return smartNotes.trim().toUpperCase();
};

export const formatSmartNotes = (smartNotes = '') => smartNotes.replace(/\|/g, '\n')
export const smartNotesToList = (smartNotes = '') => smartNotes?.split('|').map(comment => comment.trim())

const validateProduct = (product, validation) => {
    const { composition = [], variants = [] } = product
    const variantsGrouped = keyBy(composition.filter(v => v.variant), 'variantGroup')
    const variantsGroupedSelected = keyBy(variants, 'variantGroup')


    // console.log('validateProduct', product)
    // console.log('variantsGrouped', variantsGrouped)
    // console.log('variantsGroupedSelected', variantsGroupedSelected)


    Object.keys(variantsGrouped).forEach(variantGroup => {
        const _variantGroup = variantGroup === 'undefined' ? 'Variedad' : variantGroup
        if (!variantsGroupedSelected[_variantGroup]) {
            validation.valid = false
            validation.severity = 'warning'
            validation.message = `No se ha seleccionado ${_variantGroup} para ${product.name || product.description}`
            validation.break = true
        }
    })

    return validation
}
