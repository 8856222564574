import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, CircularProgress } from '@material-ui/core'
import logo from '../img/LOGO_BLANCO.png'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#F9D100',
        height: '100vh'
    },
    logo: {
        width: 100,
        height: 'auto'
    },
    circularProgress: {
        color: '#FFFF'
    }
}))

export const SplashPage = () => {
    const classes = useStyles()

    return (
        <Fragment>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item >
                    <img src={logo} className={classes.logo} alt="Burgerhouse" />
                </Grid>
                <Grid item>
                    <CircularProgress
                        className={classes.circularProgress}
                        size={40}
                        thickness={4}
                    />
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default SplashPage