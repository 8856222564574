//import { createContext } from 'react'
//import FirebaseApp from 'firebase/app'
//import 'firebase/firestore'
import FirebaseApp from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/messaging'


const config = {
  appId: process.env.REACT_APP_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}

/* const Firebase = () => {
  if (!FirebaseApp.apps.length) {
    FirebaseApp.initializeApp(config)
    FirebaseApp.firestore()
      .enablePersistence({ synchronizeTabs: true })
      .catch(err => console.error(err))
  }
} */

const initializeMessaging = (app) => {
  if (("Notification" in window)) {
    if (Notification.permission === "granted") {
      console.log('initializeMessaging')
      // Initialize Firebase Cloud Messaging and get a reference to the service
      const messaging = app.messaging()
      messaging.getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
        .then(currentToken => {
          if (currentToken) {
            console.log('currentToken', currentToken)
          } else {
            console.log('can not get token')
          }
        })


      messaging.onMessage((payload) => {
        try {
          // console.log('onMessage', payload)
          console.log('messaging.onMessage')

          const { title = '', body = '', icon } = payload.notification
          const logo = '/logoA.png'
          const notificationOptions = {
            body: body,
            icon: icon || logo, // path to your "fallback" firebase notification logo
          }

          // Muestra la notificación en el navegador (cuando está en primer plano)
          const notification = new Notification(title, notificationOptions);
          notification.onclick = () => {
            notification.close();
            window.parent.focus();
          };
        } catch (err) {
          console.log('messaging.onMessage', err)
        }
      })
    }
  }
}


class Firebase {
  constructor() {
    if (!FirebaseApp.apps.length) {
      const app = FirebaseApp.initializeApp(config)
      initializeMessaging(app)
      /* FirebaseApp.firestore()
        .enablePersistence({ synchronizeTabs: true })
        .catch(err => console.error(err)) */
    }

    // instance variables
    this.db = FirebaseApp.firestore()
    this.businessInfoCollection = this.db.collection('businessInfo')
    this.clientsCollection = this.db.collection('clients')
    this.configCollection = this.db.collection('config')
    this.errorsCollection = this.db.collection('errors')
    this.orderStateCollection = this.db.collection('orderState')
    this.ordersCollection = this.db.collection('orders')
    this.productsCollection = this.db.collection('products')
    this.regionsCollection = this.db.collection('regions')
    this.staffCollection = this.db.collection('staff')
    this.usersCollection = this.db.collection('users')
  }
}

//const FirebaseContext = createContext(null)
/*
  export { Firebase, FirebaseContext, FirebaseApp } 
  */

export default Firebase